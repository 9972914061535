import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import Vuex from 'vuex'

var VueCookie = require('vue-cookie');
var PulseLoader = require('vue-spinner/src/PulseLoader.vue');

Vue.use(Vuex)
Vue.use(VueCookie);
Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.component('pulse-loader', require('vue-spinner/src/PulseLoader.vue'));

Vue.config.productionTip = true
Vue.prototype.$apihost = (Vue.config.productionTip) ? 'https://gripandflip-l66pfdjzqq-uw.a.run.app' : 'http://toru:50020'

Vue.mixin({
  methods: {
    currConvert(str) {
      switch (str) {
        default:
          return "$";
      }
    }
  },
})

const store = new Vuex.Store({
  state: {
    loggedIn: false,
  },
  mutations: {
    logout(state) {
      state.loggedIn = false
    },
    login(state) {
      state.loggedIn = true
    }
  }
})


new Vue({
  components: {
    'PulseLoader': PulseLoader
  },
  store: store,
  router,
  render: h => h(App)
}).$mount('#app')
