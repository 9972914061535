import timeout from 'utils'

<template>
  <div id="pull">
    <div v-if="this.$store.state.loggedIn" id="hide">
      <inventory />
    </div>
    <div v-else id="hide">
      <p>Grip and Flip will help your discogs sales stay fresh.</p>
      <p>
        Grip and Flip periodically checks your active sales and adjusts the sale
        price according to a schedule you set - reducing the price of an unsold
        item by a fixed amount until it reduces a minimum amount. For example,
        you can reduce the price of unsold items by five dollars (or pounds, or
        euros...) every week until it reaches a mimimum of 10 dollars.
      </p>
      <p>
        Grip and Flip will then make the reductions for you at the schedule you
        set until either the item is sold or it reaches your specified minimum
        value.
      </p>
      <p>
        To try it, login (using your discogs account) and enroll one of your
        sale items in Grip and Flip. All Grip and Flip Activity is recorded and
        you can set your chosen sale strategy in the settings page.
      </p>
    </div>
  </div>
</template>

<script>
import Inventory from "./Inventory.vue";
export default {
  name: "pull",
  components: {
    Inventory,
  },
  data() {
    return {
      message: "",
    };
  },
  beforeMount() {},
  methods: {},
};
</script>

<style scoped>
p {
  margin-bottom: 20px;
}
</style>
