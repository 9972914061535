<template>
  <div id="settings">
    Strategies:
    <ul>
      <li>
        <div v-if="this.editing" id="hide">
          Default: Reduce price by $<input
            id="reduce"
            v-model="ireduction"
          />every <input id="period" v-model="iperiod" /> days never going under
          $<input id="minimum" v-model="iminimum" />.
          <a href="#" @click="save">[save]</a>
          <a href="#" @click="cancel">[cancel]</a>
        </div>
        <div v-else>
          Default: Reduce price by {{ currConvert(currency)
          }}{{ strategy.reduction }} every
          {{ format_period(strategy.period) }} days, never going under
          {{ currConvert(currency) }}{{ strategy.minimum }}
          <a href="#" @click="flipEdit">[edit]</a>
        </div>
      </li>
      <li>
        Auto Enroll all sales into grip and flip:
        <input
          type="checkbox"
          id="checkbox"
          @change="autoEnroll($event)"
          v-model="checked"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
momentDurationFormatSetup(moment);

export default {
  name: "settings",
  components: {},
  data() {
    return {
      checked: false,
      currency: this.$cookie.get("gripandflip-currency"),
      ireduction: 5,
      iminimum: 2.0,
      iperiod: 14,
      editing: false,
      strategy: { reduction: 10, minimum: 0 },
    };
  },
  mounted() {
    this.getStrategy();
  },
  methods: {
    cancel() {
      this.editing = false;
    },
    flipEdit() {
      this.editing = true;
    },
    async save() {
      const gguid = this.$cookie.get("gripandflip-login");
      if (gguid.length > 0) {
        const request = new Request(
          this.$apihost + "/gripandflip.GripAndFlipService/SaveStrategy",
          {
            method: "POST",
            mode: "cors",
            cache: "default",
            body: JSON.stringify({
              guid: gguid,
              strategy: {
                name: "default",
                bstrategy: {
                  reduction: this.ireduction,
                  period: this.iperiod * 24 * 60 * 60,
                  minimum: this.iminimum,
                },
              },
            }),
          }
        );
        try {
          const res = await fetch(request);
          const data = await res.json();
          console.log("WHAT " + JSON.stringify(data));
          this.getStrategy();
        } catch (p) {
          console.log("Error in getting strategy: " + p);
        }
      }
      this.editing = false;
    },
    format_period(value) {
      return moment.duration(value, "seconds").format("d");
    },
    async autoEnroll() {
      var message =
        "This will enroll all eligible sales into gripandflip. Are you sure?";
      if (!this.checked) {
        message = "This will remove all sales from gripandflip. Are you sure?";
      }
      var conf = confirm(message);
      if (conf) {
        const gguid = this.$cookie.get("gripandflip-login");
        if (gguid.length > 0) {
          const request = new Request(
            this.$apihost + "/gripandflip.GripAndFlipService/SaveUser",
            {
              method: "POST",
              mode: "cors",
              cache: "default",
              body: JSON.stringify({
                guid: gguid,
                global_enroll: this.checked ? 1 : 2,
              }),
            }
          );
          try {
            await fetch(request);
          } catch (p) {
            console.log("Error in getting strategy: " + p);
          }
        }
      } else {
        const ov = this.checked;
        setTimeout(() => {
          this.checked = !ov;
        }, 10);
      }
    },
    async getStrategy() {
      const gguid = this.$cookie.get("gripandflip-login");
      if (gguid.length > 0) {
        const request = new Request(
          this.$apihost + "/gripandflip.GripAndFlipService/GetStrategy",
          {
            method: "POST",
            mode: "cors",
            cache: "default",
            body: JSON.stringify({
              guid: gguid,
              name: "default",
            }),
          }
        );
        try {
          const res = await fetch(request);
          const data = await res.json();
          console.log("DATA " + JSON.stringify(data));
          this.strategy = data.strategies[0].bstrategy;
          this.checked = data.isAutoEnrolled;
        } catch (p) {
          console.log("Error in getting strategy: " + p);
        }
      }
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>