<template>
  <div class="about">Thanks</div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
  props: {
    oauth_token: String,
    oauth_verifier: String,
  },
  mounted() {
    this.getToken();
  },
  methods: {
    async getToken() {
      const request = new Request(
        this.$apihost + "/gripandflip.GripAndFlipService/GetToken",
        {
          method: "POST",
          mode: "cors",
          cache: "default",
          body: JSON.stringify({
            verifier: this.oauth_verifier,
            token: this.oauth_token,
          }),
        }
      );
      var res = await fetch(request);
      var data = await res.json();
      this.$cookie.set("gripandflip-login", data.cookieToken, {
        expires: "1Y",
      });
      this.$cookie.set("gripandflip-currency", data.currency, {
        expires: "1Y",
      });
      this.$store.commit("login");
      this.$router.push("/");
    },
  },
};
</script>
