<template>
  <div class="about">Thanks</div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
  props: {
    cookie: String,
  },
  mounted() {
    this.getToken();
  },
  methods: {
    async getToken() {
      this.$cookie.set("gripandflip-login", this.cookie, {
        expires: "1Y",
      });
      this.$cookie.set("gripandflip-currency", "USD", {
        expires: "1Y",
      });
      this.$store.commit("login");
      this.$router.push("/");
    },
  },
};
</script>
