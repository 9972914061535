<template>
  <div v-if="activities && activities.length > 0" id="hide">
    Grip and Flip activity:
    <clip-loader
      v-if="loading"
      :loading="loading"
      :color="color"
      :size="size"
      id="hide"
    ></clip-loader>
    <ul id="activities">
      <li v-for="item in activities" :key="item.timestamp">
        {{ format_date(item.timestamp) }}: {{ item.description }}
      </li>
    </ul>
  </div>
  <div v-else id="hide">You have no current activity.</div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import moment from "moment";

export default {
  data() {
    return {
      activities: [],
      currency: this.$cookie.get("gripandflip-currency"),
    };
  },
  beforeMount() {
    this.getActivities();
  },
  components: {
    ClipLoader,
  },
  methods: {
    format_date(value) {
      return moment.unix(value).fromNow();
    },
    async getActivities() {
      this.loading = true;
      const gguid = this.$cookie.get("gripandflip-login");
      console.log("WITH ID " + gguid);
      if (gguid.length > 0) {
        const request = new Request(
          this.$apihost + "/gripandflip.GripAndFlipService/GetActivity",
          {
            method: "POST",
            mode: "cors",
            cache: "default",
            body: JSON.stringify({
              guid: gguid,
              num: 10,
            }),
          }
        );
        try {
          const res = await fetch(request);
          const data = await res.json();
          this.activities = data.activities;
          console.log("Received data for page # " + JSON.stringify(data));
        } catch (p) {
          console.log("Error in getting inventory: " + p);
        }
      }
      this.loading = false;
    },
  },
};
</script>