<template>
  <div v-if="this.$store.state.loggedIn" id="hide">
    <div v-if="inventory.length > 0" id="hide">
      <div id="pagination">(Page {{ page }} / {{ pages }}):</div>
      <clip-loader
        v-if="loading"
        :loading="loading"
        :color="color"
        :size="size"
        id="hide"
      ></clip-loader>
      <table border="0" width="90%" class="center">
        <tr>
          <th>Item</th>
          <th></th>
          <th>Price</th>
          <th>Last Updated</th>
          <th>Strategy</th>
        </tr>
        <tr
          v-for="item in inventory"
          :key="item.id"
          v-bind:class="{ inactive: item.status != 'For Sale' }"
        >
          <td>
            <a v-bind:href="item.discogsUrl">{{ item.id }}</a>
          </td>
          <td>{{ item.artist }} - {{ item.title }}</td>
          <td>{{ currConvert(item.price.currency) }}{{ item.price.value }}</td>
          <td>{{ format_date(item.dateAdded) }}</td>
          <td>
            <select name="itemupdate" @change="updateItem($event, item.id)">
              <option
                v-for="strategy in strategies"
                v-bind:key="strategy"
                :selected="strategy == item.currentStrategy"
                :disabled="item.status != 'For Sale'"
              >
                {{ strategy }}
              </option>
            </select>
          </td>
        </tr>
      </table>
      <div id="pagination">
        <button v-on:click="decrementPage">Previous</button>
        / <button v-on:click="incrementPage">Next</button>
      </div>
    </div>
    <div v-else id="hide">
      You don't have anything listed for sale right now.
    </div>
  </div>
  <div v-else id="hide">You need to login to see your inventory.</div>
</template>

<style scoped>
.inactive {
  color: #aaafb4;
}

table {
  text-align: left;
}
.center {
  margin-left: auto;
  margin-right: auto;
}
#pagination {
  float: right;
  margin-right: 10%;
}
.button {
  border: 0;
}
</style>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import moment from "moment";

export default {
  data() {
    return {
      currency: "USD",
      page: 1,
      pages: 10,
      inventory: [],
      loading: false,
      strategies: ["No Adjustment", "default"],
    };
  },
  beforeMount() {
    this.getInventory();
  },
  components: {
    ClipLoader,
  },
  methods: {
    decrementPage: function () {
      this.page--;
      if (this.page < 1) {
        this.page = 1;
      } else {
        this.getInventory();
      }
    },
    incrementPage: function () {
      this.page++;
      if (this.page > this.pages) {
        this.page = this.pages;
      } else {
        this.getInventory();
      }
    },
    format_date(value) {
      return moment.unix(value).fromNow();
    },
    async updateItem(event, id) {
      const gguid = this.$cookie.get("gripandflip-login");
      const request = new Request(
        this.$apihost + "/gripandflip.GripAndFlipService/SaveItem",
        {
          method: "POST",
          mode: "cors",
          cache: "default",
          body: JSON.stringify({
            guid: gguid,
            inventory_id: id,
            new_strategy: event.target.value,
          }),
        }
      );
      try {
        const res = await fetch(request);
        const data = await res.json();
        console.log("Got " + data);
      } catch (p) {
        console.log("Received data from update: " + p);
      }
    },
    async getInventory() {
      this.loading = true;
      const gguid = this.$cookie.get("gripandflip-login");
      if (gguid.length > 0) {
        const request = new Request(
          this.$apihost + "/gripandflip.GripAndFlipService/GetInventory",
          {
            method: "POST",
            mode: "cors",
            cache: "default",
            body: JSON.stringify({
              guid: gguid,
              page: 10,
              start: this.page * 10 - 10,
            }),
          }
        );
        try {
          const res = await fetch(request);
          const data = await res.json();
          this.inventory = data.items;
          this.pages = Math.ceil(data.maxItems / 10);
          console.log(
            "Received " +
              this.inventory.length +
              " items: " +
              JSON.stringify(this.inventory[0])
          );
        } catch (p) {
          console.log("Error in getting inventory: " + p);
        }
        this.loading = false;
      }
    },
  },
};
</script>
