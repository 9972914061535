import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import Callback from '../views/Callback.vue'
import FakeLogin from '../views/FakeLogin.vue'
import Settings from '../views/Settings.vue'
import Activities from '../views/Activities.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Main
  },
  {
    path: '/discogs_callback',
    name: 'Callback',
    component: Callback,
    props: (route) => ({ oauth_token: route.query.oauth_token, oauth_verifier: route.query.oauth_verifier })
  },
  {
    path: '/fakelogin',
    name: 'FakeLogin',
    component: FakeLogin,
    props: (route) => ({ cookie: route.query.cookie })
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
  },
  {
    path: '/activities',
    name: 'Activities',
    component: Activities,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
